export const tokenContractAddress =
"0x482d699f92E64cFdbB997C245223459071E343c4";

export const todoContract =
"0x482d699f92E64cFdbB997C245223459071E343c4";

export const tokendropcontract =
"0x482d699f92E64cFdbB997C245223459071E343c4";

export const submitWalletcontracts =
"0x2b04a113B5553158b0fe3F6d63250674910D601a";