import React from 'react';
import { motion } from 'framer-motion';
import { 
    Twitter, 
    MessageCircle, // Instead of Telegram
    Github,
    MessagesSquare, // Instead of Discord
    ArrowUpRight, 
    Globe,
    Mail,
    Shield,
    BookOpen,
    FileText,
    AlertCircle
} from 'lucide-react';
import { scorp } from '../images';

// Types
interface FooterLink {
  label: string;
  href: string;
  icon?: React.ReactNode;
  isExternal?: boolean;
}

interface FooterSection {
  title: string;
  links: FooterLink[];
}

// Footer Data
const footerSections: FooterSection[] = [
  {
    title: "Quick Links",
    links: [
      { label: "Home", href: "#home" },
      { label: "About", href: "#about" },
      { label: "Tokenomics", href: "#tokenomics" },
      { label: "Roadmap", href: "#roadmap" },
      { label: "Buy $SCORP", href: "#buy" },
    ]
  },
  {
    title: "Resources",
    links: [
      { 
        label: "Whitepaper", 
        href: "https://whitepaper.scorpionworldtoken.xyz", 
        icon: <BookOpen className="w-4 h-4" />,
        isExternal: true
      },
      // { 
      //   label: "Documentation", 
      //   href: "#", 
      //   icon: <FileText className="w-4 h-4" />,
      //   isExternal: true
      // },
      // { 
      //   label: "Audit Report", 
      //   href: "#", 
      //   icon: <Shield className="w-4 h-4" />,
      //   isExternal: true
      // },
      { 
        label: "Token Contract", 
        href: "https://base-sepolia.blockscout.com/address/0x482d699f92E64cFdbB997C245223459071E343c4", 
        icon: <Globe className="w-4 h-4" />,
        isExternal: true
      }
    ]
  },
  {
    title: "Support",
    links: [
      { 
        label: "Medium Blog", 
        href: "https://scorpioworldnews.medium.com/meet-scorp-b78dded186a3", 
        icon: <AlertCircle className="w-4 h-4" />
      },
      { 
        label: "FAQ", 
        href: "#faq", 
        icon: <AlertCircle className="w-4 h-4" />
      },
      { 
        label: "Contact", 
        href: "mailto:contact@scorpionworldtoken.xyz", 
        icon: <Mail className="w-4 h-4" />,
        isExternal: true
      }
    ]
  }
];

// Updated socialLinks with correct icons
const socialLinks = [
     { icon: <Github className="w-5 h-5" />, href: "https://github.com/scorptoken", label: "Github" },
  ];
  

const Footer: React.FC = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="relative bg-slate-950 border-t border-blue-800/30">
      {/* Background Effects */}
      <div className="absolute inset-0 overflow-hidden">
        <div className="absolute w-[500px] h-[500px] bg-orange-500/5 rounded-full blur-3xl -top-48 -left-48" />
        <div className="absolute w-[500px] h-[500px] bg-blue-500/5 rounded-full blur-3xl -bottom-48 -right-48" />
      </div>

      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Main Footer Content */}
        <div className="py-12 lg:py-16">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-6 gap-12 lg:gap-8">
            {/* Brand Section */}
            <div className="lg:col-span-2">
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                className="space-y-4"
              >
                {/* Logo */}
                <div className="flex items-center gap-3">
                  <img
                    src={scorp}
                    alt="SCORP Token"
                    className="w-10 h-10 rounded-xl"
                  />
                  <div>
                    <h3 className="text-lg font-bold bg-gradient-to-r from-orange-400 to-orange-300 text-transparent bg-clip-text">
                    Scorpion World
                    </h3>
                    <p className="text-xs text-blue-100/60">
                      The Next Generation DeFi
                    </p>
                  </div>
                </div>

                {/* Description */}
                <p className="text-blue-100/60 text-sm max-w-sm">
                  Join the revolution with $SCORP - where innovation meets opportunity. 
                  Building the future of decentralized finance.
                </p>

              </motion.div>
            </div>

            {/* Footer Sections */}
            {footerSections.map((section, sectionIndex) => (
              <div key={section.title} className="lg:col-span-1">
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: 0.2 + sectionIndex * 0.1 }}
                >
                  <h3 className="text-sm font-semibold text-orange-400 mb-4">
                    {section.title}
                  </h3>
                  <ul className="space-y-3">
                    {section.links.map((link, linkIndex) => (
                      <motion.li
                        key={link.label}
                        initial={{ opacity: 0, x: -20 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ duration: 0.3, delay: 0.3 + linkIndex * 0.1 }}
                      >
                        <a
                          href={link.href}
                          target={link.isExternal ? "_blank" : undefined}
                          rel={link.isExternal ? "noopener noreferrer" : undefined}
                          className="group flex items-center gap-2 text-sm text-blue-100/60 hover:text-orange-400 transition-colors"
                        >
                          {link.icon}
                          <span>{link.label}</span>
                          {link.isExternal && (
                            <ArrowUpRight className="w-3 h-3 opacity-0 -translate-y-1 group-hover:opacity-100 
                              group-hover:translate-y-0 transition-all" />
                          )}
                        </a>
                      </motion.li>
                    ))}
                  </ul>
                </motion.div>
              </div>
            ))}

      
            {/* <div className="lg:col-span-2">
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.4 }}
                className="space-y-4"
              >
                <h3 className="text-sm font-semibold text-orange-400">
                  Stay Updated
                </h3>
                <p className="text-sm text-blue-100/60">
                  Subscribe to our newsletter for the latest updates and exclusive offers.
                </p>
                <form className="flex gap-2" onSubmit={(e) => e.preventDefault()}>
                  <input
                    type="email"
                    placeholder="Enter your email"
                    className="flex-1 px-4 py-2 bg-blue-900/20 border border-blue-800/30 rounded-lg 
                      text-blue-100 placeholder:text-blue-100/40 focus:outline-none focus:border-orange-500/50"
                  />
                  <motion.button
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                    className="px-4 py-2 bg-gradient-to-r from-orange-500 to-orange-600 text-white 
                      font-medium rounded-lg hover:opacity-90 transition-opacity"
                  >
                    Subscribe
                  </motion.button>
                </form>
              </motion.div>
            </div> */}
          </div>
        </div>

        {/* Bottom Bar */}
        <div className="py-6 border-t border-blue-800/30">
          <div className="flex flex-col md:flex-row justify-between items-center gap-4">
            <p className="text-sm text-blue-100/60">
              © {currentYear} Scorpion World. All rights reserved.
            </p>
            {/* <div className="flex items-center gap-6">
              <a href="/privacy" className="text-sm text-blue-100/60 hover:text-orange-400 transition-colors">
                Privacy Policy
              </a>
              <a href="/terms" className="text-sm text-blue-100/60 hover:text-orange-400 transition-colors">
                Terms of Service
              </a>
            </div> */}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;