import React, { useState } from 'react';
import { 
  useConnect,
  useDisconnect,
  useAddress,
  useContract,
  useContractRead,
  useContractEvents,
  Web3Button,
  ConnectWallet
} from '@thirdweb-dev/react';
import { ethers } from 'ethers';
import { 
  ShieldCheck, 
  BookOpen, 
  Wallet, 
  MessageSquare, 
  CheckCircle2,
  ChevronRight,
  ChevronLeft,
  X,
  AlertCircle,
  Sparkles,
  Users,
  Clock,
  ArrowRight,
  Lock,
  ExternalLink
} from 'lucide-react';

interface Step {
  id: number;
  title: string;
  description: string;
  icon: React.ReactNode;
}

interface Toast {
  id: number;
  type: 'success' | 'error' | 'info';
  title: string;
  message: string;
}

interface Web3Error {
  message?: string;
  reason?: string;
  code?: string | number;
}

interface WhitelistEvent {
  data: {
    sender: string;
    timestamp: bigint;
    message: string;
  };
}

const STEPS: Step[] = [
  {
    id: 1,
    title: "Learn About SAFE",
    description: "Understand the basics of our token and whitelist process",
    icon: <BookOpen className="w-6 h-6" />
  },
  {
    id: 2,
    title: "Connect Wallet",
    description: "Set up and connect your Web3 wallet",
    icon: <Wallet className="w-6 h-6" />
  },
  {
    id: 3,
    title: "Submit Application",
    description: "Complete your whitelist submission",
    icon: <MessageSquare className="w-6 h-6" />
  },
  {
    id: 4,
    title: "Confirmation",
    description: "Review your submission details",
    icon: <CheckCircle2 className="w-6 h-6" />
  }
];

const CONTRACT_ADDRESS = "0x2b04a113B5553158b0fe3F6d63250674910D601a";
const WHITELIST_FEE = "0.001";
const MAX_SPOTS = 1000;
const SALE_START = new Date('2024-12-01T00:00:00Z');

export default function Web3SetupWizard() {
  const [currentStep, setCurrentStep] = useState(1);
  const [message, setMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [toasts, setToasts] = useState<Toast[]>([]);
  const [hasReadTerms, setHasReadTerms] = useState(false);
  const [showWizard, setShowWizard] = useState(false);
  const handleFaucetClick = () => {
    window.open('https://faucets.chain.link/', '_blank');
  };

  
  const address = useAddress();
   useDisconnect();

// Modify the CTA button click handler
const handleGetStarted = () => {
  setShowWizard(true);
  setTimeout(() => {
    document.getElementById('wizard-content')?.scrollIntoView({ 
      behavior: 'smooth',
      block: 'start'
    });
  }, 100);
};
  
  const { contract } = useContract(CONTRACT_ADDRESS);
  
  const { data: events = [], refetch: refetchEvents } = useContractEvents(
    contract,
    undefined,
    { subscribe: true }
  );

  const getErrorMessage = (error: Web3Error): string => {
    if (error.reason) {
      return error.reason;
    }
    
    if (error.message) {
      let message = error.message;
      
      if (message.includes('execution reverted:')) {
        message = message.split('execution reverted:')[1].trim();
      }
      
      if (message.includes('user rejected transaction')) {
        return 'Transaction was rejected. Please try again.';
      }
      
      if (message.toLowerCase().includes('insufficient funds')) {
        return `Insufficient funds. Please ensure you have ${WHITELIST_FEE} ETH plus gas fees.`;
      }
      
      return message;
    }
    
    if (error.code) {
      switch (error.code) {
        case 4001:
          return 'Transaction was rejected. Please try again.';
        case -32603:
          return 'Internal blockchain error. Please try again.';
        default:
          return `Transaction failed with code: ${error.code}`;
      }
    }
    
    return 'Transaction failed. Please try again or contact support.';
  };

  const addToast = (type: 'success' | 'error' | 'info', title: string, message: string) => {
    const id = Date.now();
    setToasts(prev => [...prev, { id, type, title, message }]);
    setTimeout(() => removeToast(id), 5000);
  };

  const removeToast = (id: number) => {
    setToasts(prev => prev.filter(toast => toast.id !== id));
  };

  const handleNext = () => {
    if (currentStep < STEPS.length) {
      setCurrentStep(prev => prev + 1);
    }
  };

  const handleBack = () => {
    if (currentStep > 1) {
      setCurrentStep(prev => prev - 1);
    }
  };

  const handleSubmit = async () => {
    if (!contract || !message) return;

    setIsSubmitting(true);
    addToast('info', 'Initiating Transaction', 'Please confirm in your wallet');
    
    try {
      await contract.call(
        "buyMeACoffee",
        [message],
        { value: ethers.utils.parseEther(WHITELIST_FEE) }
      );
      
      addToast('success', 'Submission Successful', 'Welcome to the whitelist!');
      setCurrentStep(4);
      await refetchEvents();
      
    } catch (err) {
      const error = err as Web3Error;
      const errorMessage = getErrorMessage(error);
      addToast('error', 'Transaction Failed', errorMessage);
      
    } finally {
      setIsSubmitting(false);
    }
  };

  const ToastNotification = ({ toast }: { toast: Toast }) => (
    <div className={`
      ${toast.type === 'success' ? 'bg-green-500' :
        toast.type === 'error' ? 'bg-red-500' : 'bg-blue-500'
      } text-white p-4 rounded-lg shadow-lg flex items-start justify-between
      transform transition-all duration-300 ease-in-out
    `}>
      <div>
        <h4 className="font-semibold">{toast.title}</h4>
        <p className="text-sm mt-1">{toast.message}</p>
      </div>
      <button 
        onClick={() => removeToast(toast.id)} 
        className="ml-4 text-white/80 hover:text-white"
      >
        <X className="w-4 h-4" />
      </button>
    </div>
  );

  const renderStepContent = () => {
    switch (currentStep) {
      case 1:
        return (
          <div className="space-y-6">
               <div className="bg-gradient-to-r from-blue-500/10 to-orange-500/10 p-6 rounded-xl border border-blue-500/20">
      <h3 className="text-xl font-bold text-white mb-4">About Scorp Token SAFE</h3>
      <div className="space-y-4 text-gray-300">
        <p>
          The Scorp Token SAFE (Simple Agreement for Future Equity) offers early 
          participation in our ecosystem with these benefits:
        </p>
        <ul className="space-y-3">
          <li className="flex items-start">
            <CheckCircle2 className="w-5 h-5 text-orange-500 mr-2 mt-1" />
            <span>20% discount on the public sale price</span>
          </li>
          <li className="flex items-start">
            <CheckCircle2 className="w-5 h-5 text-orange-500 mr-2 mt-1" />
            <span>Guaranteed allocation at Token Generation Event (TGE)</span>
          </li>
          <li className="flex items-start">
            <CheckCircle2 className="w-5 h-5 text-orange-500 mr-2 mt-1" />
            <span>6-month cliff followed by 12-month vesting</span>
          </li>
        </ul>
        
        {/* Faucet Button */}
        <div className="mt-6">
          <button
            onClick={handleFaucetClick}
            className="flex items-center gap-2 bg-gradient-to-r from-blue-500 to-orange-500 text-white px-4 py-2 rounded-lg hover:opacity-90 transition-opacity"
          >
            Claim Base Sepolia ETH
            <ExternalLink className="w-4 h-4" />
          </button>
          <p className="text-sm text-gray-400 mt-2">
            Get testnet ETH from Chainlink Faucet to interact with the contract
          </p>
        </div>
      </div>
    </div>
            
            <div className="bg-black/30 p-6 rounded-xl border border-blue-500/20">
              <h4 className="text-lg font-semibold text-white mb-3">Important Details</h4>
              <div className="grid grid-cols-2 gap-4">
                <div className="p-4 bg-black/40 rounded-lg">
                  <p className="text-gray-400 text-sm">Whitelist Fee</p>
                  <p className="text-xl font-bold text-white">{WHITELIST_FEE} ETH</p>
                </div>
                <div className="p-4 bg-black/40 rounded-lg">
                  <p className="text-gray-400 text-sm">Sale Start</p>
                  <p className="text-xl font-bold text-white">{SALE_START.toLocaleDateString()}</p>
                </div>
              </div>
            </div>

            <div className="flex items-center space-x-2">
              <input
                type="checkbox"
                id="terms"
                checked={hasReadTerms}
                onChange={(e) => setHasReadTerms(e.target.checked)}
                className="w-4 h-4 rounded border-gray-300"
              />
              <label htmlFor="terms" className="text-gray-300 text-sm">
                I have read and understand the SAFE terms
              </label>
            </div>
          </div>
        );
      
      case 2:
        return (
          <div className="space-y-6">
            <div className="bg-gradient-to-r from-blue-500/10 to-orange-500/10 p-6 rounded-xl border border-blue-500/20">
              <h3 className="text-xl font-bold text-white mb-4">Connect Your Wallet</h3>
              <p className="text-gray-300 mb-6">
                To participate in the whitelist, you'll need to connect a Web3 wallet. 
                We support MetaMask, WalletConnect, and other popular providers.
              </p>
              
              <div className="flex justify-center">
                <ConnectWallet 
                  theme="dark"
                  btnTitle="Connect Wallet"
                  // className="!bg-gradient-to-r !from-blue-600 !to-orange-600 !hover:from-blue-700 !hover:to-orange-700"
                />
              </div>
            </div>

            {!address && (
              <div className="bg-orange-500/10 border border-orange-500/20 rounded-lg p-4 flex items-start space-x-3">
                <AlertCircle className="w-5 h-5 text-orange-500 flex-shrink-0 mt-0.5" />
                <div className="text-sm text-orange-200">
                  <p className="font-semibold">Wallet Required</p>
                  <p>You need to connect your wallet to proceed to the next step.</p>
                </div>
              </div>
            )}
          </div>
        );
      
      case 3:
        return (
          <div className="space-y-6">
            <div className="bg-gradient-to-r from-blue-500/10 to-orange-500/10 p-6 rounded-xl border border-blue-500/20">
              <h3 className="text-xl font-bold text-white mb-4">Complete Your Submission</h3>
              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-300 mb-2">
                    Why are you interested in Scorp Token?
                  </label>
                  <textarea
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    placeholder="Share your thoughts..."
                    rows={4}
                    className="w-full px-4 py-3 bg-black/30 border border-blue-500/20 rounded-lg 
                             text-white placeholder-gray-500 focus:outline-none focus:ring-2 
                             focus:ring-orange-500 focus:border-transparent"
                    disabled={isSubmitting}
                  />
                </div>

                <div className="bg-blue-500/10 border border-blue-500/20 rounded-lg p-4">
                  <div className="flex items-center justify-between mb-2">
                    <span className="text-gray-300">Whitelist Fee</span>
                    <span className="text-white font-semibold">{WHITELIST_FEE} ETH</span>
                  </div>
                  <div className="flex items-center justify-between">
                    <span className="text-gray-300">Your Address</span>
                    <span className="text-white font-mono text-sm">
                      {address?.slice(0, 6)}...{address?.slice(-4)}
                    </span>
                  </div>
                </div>

                <button
                  onClick={handleSubmit}
                  disabled={!message || isSubmitting}
                  className={`
                    w-full py-3 px-4 rounded-lg font-semibold transition-all
                    ${message && !isSubmitting
                      ? 'bg-gradient-to-r from-blue-600 to-orange-600 hover:from-blue-700 hover:to-orange-700 text-white'
                      : 'bg-gray-600 text-gray-300 cursor-not-allowed'
                    }
                  `}
                >
                  {isSubmitting ? "Submitting..." : "Complete Submission"}
                </button>
              </div>
            </div>
          </div>
        );
      
      case 4:
        return (
          <div className="text-center space-y-6">
            <div className="flex justify-center">
              <div className="bg-green-500/20 rounded-full p-3">
                <Sparkles className="w-12 h-12 text-green-500" />
              </div>
            </div>
            <div>
              <h3 className="text-2xl font-bold text-white mb-2">Welcome to the Whitelist!</h3>
              <p className="text-gray-300">
                Your submission has been confirmed on the blockchain. You'll receive updates
                about the token sale closer to the launch date.
              </p>
            </div>
            <div className="bg-black/30 p-6 rounded-xl border border-green-500/20">
              <h4 className="text-lg font-semibold text-white mb-3">Next Steps</h4>
              <ul className="space-y-3 text-left">
                <li className="flex items-start">
                  <CheckCircle2 className="w-5 h-5 text-green-500 mr-2 mt-1" />
                  <span className="text-gray-300">
                    Mark your calendar for {SALE_START.toLocaleDateString()}
                  </span>
                </li>
                <li className="flex items-start">
                <CheckCircle2 className="w-5 h-5 text-green-500 mr-2 mt-1" />
                  <span className="text-gray-300">Join our Telegram community for updates</span>
                </li>
                <li className="flex items-start">
                  <CheckCircle2 className="w-5 h-5 text-green-500 mr-2 mt-1" />
                  <span className="text-gray-300">Follow our Twitter for announcements</span>
                </li>
              </ul>
            </div>
          </div>
        );
      
      default:
        return null;
    }
  };

  const typedEvents = events as unknown as WhitelistEvent[];
  const totalSubmissions = typedEvents.length;
  const remainingSpots = MAX_SPOTS - totalSubmissions;
  const daysUntilSale = Math.max(0, Math.floor((SALE_START.getTime() - Date.now()) / (1000 * 60 * 60 * 24)));

  return (
    <div className="min-h-screen bg-gradient-to-br from-black via-blue-950 to-orange-950 py-12">
      {/* Toast Container */}
      <div className="fixed top-4 right-4 z-50 space-y-2">
        {toasts.map(toast => (
          <ToastNotification key={toast.id} toast={toast} />
        ))}
      </div>

      <div className="max-w-4xl mx-auto px-4">
      {/* Header Section */}

<div className="relative">
  {/* Animated Background Elements */}
  <div className="absolute">
    <div className="absolute w-full h-full opacity-30">
      <div className="absolute top-0 -left-4 w-96 h-96 bg-blue-500/30 rounded-full mix-blend-multiply filter blur-3xl animate-blob" />
      <div className="absolute top-0 -right-4 w-96 h-96 bg-orange-500/30 rounded-full mix-blend-multiply filter blur-3xl animate-blob animation-delay-2000" />
      <div className="absolute -bottom-8 left-20 w-96 h-96 bg-purple-500/30 rounded-full mix-blend-multiply filter blur-3xl animate-blob animation-delay-4000" />
    </div>
  </div>

  <div className="relative">
    {/* Logo and Title */}
    <div className="text-center mb-8">
      <div className="inline-block relative">
        {/* Logo Glow Effect */}
        <div className="absolute -inset-2 bg-gradient-to-r from-blue-500 to-orange-500 rounded-full opacity-75 group-hover:opacity-100 blur-lg transition duration-1000 group-hover:duration-200 animate-pulse" />
        
        {/* Logo Container */}
        <div className="relative flex items-center justify-center p-1 bg-black/50 backdrop-blur-sm rounded-full">
          <div className="bg-gradient-to-r from-blue-500/10 to-orange-500/10 rounded-full p-4">
            <ShieldCheck className="w-16 h-16 text-orange-500" />
          </div>
        </div>
      </div>

      {/* Title */}
      <h1 className="mt-8 text-5xl md:text-6xl lg:text-7xl font-extrabold tracking-tight">
        <span className="inline-block text-white mb-2">Scorpion World</span>
        <br />
        <span className="inline-block relative">
          {/* SAFE Text Glow */}
          <span className="absolute -inset-2 bg-gradient-to-r from-blue-500 to-orange-500 opacity-50 blur-lg animate-pulse" />
          <span className="relative bg-gradient-to-r from-blue-400 to-orange-400 text-transparent bg-clip-text">
            SAFE Whitelist
          </span>
        </span>
      </h1>

      {/* Animated Separator */}
      <div className="flex justify-center my-8">
        <div className="relative w-24 h-1">
          <div className="absolute inset-0 bg-gradient-to-r from-blue-500 to-orange-500 animate-pulse" />
          <div className="absolute inset-0 bg-gradient-to-r from-transparent via-white/50 to-transparent animate-shimmer" />
        </div>
      </div>

      {/* Subtitle */}
      <div className="max-w-3xl mx-auto px-4">
        <p className="text-xl md:text-2xl text-gray-300 font-light leading-relaxed">
          Join the revolution in decentralized finance through our secure and 
          <span className="relative inline-block px-2">
            <span className="absolute inset-0 bg-gradient-to-r from-blue-500/20 to-orange-500/20 rounded-lg" />
            <span className="relative">guided whitelist process</span>
          </span>
        </p>
      </div>
    </div>
  </div>
</div>

{/* Add required keyframes to your CSS */}
<style>{`
  @keyframes shimmer {
    0% { transform: translateX(-100%); }
    100% { transform: translateX(100%); }
  }
  
  @keyframes blob {
    0% { transform: translate(0px, 0px) scale(1); }
    33% { transform: translate(30px, -50px) scale(1.1); }
    66% { transform: translate(-20px, 20px) scale(0.9); }
    100% { transform: translate(0px, 0px) scale(1); }
  }

  .animate-shimmer {
    animation: shimmer 2s linear infinite;
  }
  
  .animate-blob {
    animation: blob 7s infinite;
  }
  
  .animation-delay-2000 {
    animation-delay: 2s;
  }
  
  .animation-delay-4000 {
    animation-delay: 4s;
  }
`}</style>
{/* Progress Steps */}
<div className="relative mb-16">
  {/* Desktop Progress Bar (md and up) */}
  <div className="hidden md:block">
    <div className="absolute top-1/2 left-0 w-full h-0.5 bg-gradient-to-r from-gray-800 via-gray-800 to-gray-800 transform -translate-y-1/2">
      <div 
        className="h-full bg-gradient-to-r from-blue-600 to-orange-600 transition-all duration-500 ease-in-out"
        style={{ width: `${((currentStep - 1) / (STEPS.length - 1)) * 100}%` }}
      />
    </div>
    <div className="relative flex justify-between max-w-4xl mx-auto px-8">
      {STEPS.map((step) => (
        <div
          key={step.id}
          className={`flex flex-col items-center w-48 ${
            step.id === currentStep ? 'text-white' :
            step.id < currentStep ? 'text-green-500' : 'text-gray-500'
          }`}
        >
          <div className={`
            w-14 h-14 rounded-full flex items-center justify-center
            transform transition-all duration-300 group
            ${step.id === currentStep
              ? 'bg-gradient-to-r from-blue-600 to-orange-600 scale-110 shadow-lg'
              : step.id < currentStep
              ? 'bg-green-500/20 text-green-500'
              : 'bg-gray-800/50 backdrop-blur-sm'
            }
            relative z-10
          `}>
            <div className={`
              absolute inset-0 rounded-full opacity-0 group-hover:opacity-100
              transition-opacity duration-300 blur
              ${step.id === currentStep
                ? 'bg-gradient-to-r from-blue-400 to-orange-400'
                : step.id < currentStep
                ? 'bg-green-400'
                : 'bg-gray-600'
              }
            `} />
            <div className="relative z-10">
              {step.id < currentStep ? (
                <CheckCircle2 className="w-7 h-7" />
              ) : (
                step.icon
              )}
            </div>
          </div>
          <div className="mt-4 text-center transition-all duration-300">
            <p className={`font-semibold mb-1 ${
              step.id === currentStep ? 'text-white' :
              step.id < currentStep ? 'text-green-500' : 'text-gray-500'
            }`}>
              {step.title}
            </p>
            <p className="text-xs text-gray-500">{step.description}</p>
          </div>
        </div>
      ))}
    </div>
  </div>

  {/* Mobile Progress Steps (sm and down) */}
  <div className="md:hidden">
    <div className="relative">
      <div className="absolute left-6 top-0 bottom-0 w-0.5 bg-gray-800">
        <div 
          className="w-full bg-gradient-to-b from-blue-600 to-orange-600 transition-all duration-500 ease-in-out"
          style={{ height: `${((currentStep - 1) / (STEPS.length - 1)) * 100}%` }}
        />
      </div>
      <div className="space-y-8">
        {STEPS.map((step) => (
          <div
            key={step.id}
            className={`flex items-start ml-2 ${
              step.id === currentStep ? 'text-white' :
              step.id < currentStep ? 'text-green-500' : 'text-gray-500'
            }`}
          >
            <div className={`
              w-10 h-10 rounded-full flex items-center justify-center
              transform transition-all duration-300 shrink-0
              ${step.id === currentStep
                ? 'bg-gradient-to-r from-blue-600 to-orange-600 scale-110 shadow-lg'
                : step.id < currentStep
                ? 'bg-green-500/20 text-green-500'
                : 'bg-gray-800/50 backdrop-blur-sm'
              }
              relative z-10
            `}>
              {step.id < currentStep ? (
                <CheckCircle2 className="w-5 h-5" />
              ) : (
                step.icon
              )}
              {step.id === currentStep && (
                <div className="absolute inset-0 bg-gradient-to-r from-blue-600 to-orange-600 rounded-full blur opacity-50 animate-pulse" />
              )}
            </div>
            <div className="ml-4">
              <p className={`font-semibold ${
                step.id === currentStep ? 'text-white' :
                step.id < currentStep ? 'text-green-500' : 'text-gray-500'
              }`}>
                {step.title}
              </p>
              <p className="text-sm text-gray-500 mt-1">{step.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  </div>

  {/* Current Step Indicator */}
  <div className="mt-8 text-center md:hidden">
    <p className="text-gray-400 text-sm">
      Step {currentStep} of {STEPS.length}
    </p>
  </div>
</div>

        {/* Main Content Card */}
        <div className="bg-black/40 backdrop-blur-lg rounded-2xl p-8 border border-blue-900/30 mb-8">
          {renderStepContent()}
        </div>

        {/* Navigation Buttons */}
        {currentStep !== 4 && (
          <div className="flex justify-between">
            <button
              onClick={handleBack}
              disabled={currentStep === 1}
              className={`
                flex items-center px-6 py-3 rounded-lg font-semibold transition-all
                ${currentStep === 1
                  ? 'bg-gray-800 text-gray-500 cursor-not-allowed'
                  : 'bg-gray-800 text-white hover:bg-gray-700'
                }
              `}
            >
              <ChevronLeft className="w-5 h-5 mr-2" />
              Back
            </button>

            <button
              onClick={handleNext}
              disabled={
                (currentStep === 1 && !hasReadTerms) ||
                (currentStep === 2 && !address) ||
                currentStep === 3 ||
                currentStep === STEPS.length
              }
              className={`
                flex items-center px-6 py-3 rounded-lg font-semibold transition-all
                ${((currentStep === 1 && !hasReadTerms) ||
                   (currentStep === 2 && !address) ||
                   currentStep === 3)
                  ? 'bg-gray-800 text-gray-500 cursor-not-allowed'
                  : 'bg-gradient-to-r from-blue-600 to-orange-600 text-white hover:from-blue-700 hover:to-orange-700'
                }
              `}
            >
              Next
              <ChevronRight className="w-5 h-5 ml-2" />
            </button>
          </div>
        )}

        {/* Stats Bar */}
        <div className="mt-12 bg-black/40 backdrop-blur-lg rounded-xl p-6 border border-blue-900/30">
          <div className="grid grid-cols-3 gap-8">
            <div className="text-center">
              <div className="flex items-center justify-center mb-2">
                <Users className="w-5 h-5 text-blue-500 mr-2" />
                <p className="text-gray-400 text-sm">Total Whitelisted</p>
              </div>
              <p className="text-2xl font-bold text-white">{totalSubmissions}</p>
            </div>
            <div className="text-center">
              <div className="flex items-center justify-center mb-2">
                <Sparkles className="w-5 h-5 text-orange-500 mr-2" />
                <p className="text-gray-400 text-sm">Remaining Spots</p>
              </div>
              <p className="text-2xl font-bold text-white">{remainingSpots}</p>
            </div>
            <div className="text-center">
              <div className="flex items-center justify-center mb-2">
                <Clock className="w-5 h-5 text-blue-500 mr-2" />
                <p className="text-gray-400 text-sm">Days Until Sale</p>
              </div>
              <p className="text-2xl font-bold text-white">{daysUntilSale}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}