import React from 'react';
import { motion } from 'framer-motion';
import { 
  Coins, 
  Users, 
  Building2, 
  Globe,
  ArrowUpRight,
  GraduationCap,
  LineChart,
  ShieldCheck
} from 'lucide-react';
import { scorp } from '../images';

// Types
interface Feature {
  icon: React.ReactNode;
  title: string;
  description: string;
  color: string;
}

interface Benefit {
  icon: React.ReactNode;
  title: string;
  description: string;
}

// Data aligned with whitepaper
const features: Feature[] = [
  {
    icon: <Coins className="w-6 h-6" />,
    title: "Universal Income",
    description: "Regular SCORP token distribution providing reliable financial foundation for all participants.",
    color: "from-orange-500/20 to-orange-600/20"
  },
  {
    icon: <Users className="w-6 h-6" />,
    title: "Community Mining",
    description: "Sustainable Proof of Community (PoC) mining model rewarding active ecosystem participation.",
    color: "from-blue-500/20 to-blue-600/20"
  },
  {
    icon: <Building2 className="w-6 h-6" />,
    title: "Marketplace",
    description: "Decentralized platform for exchanging goods and services using SCORP tokens.",
    color: "from-green-500/20 to-green-600/20"
  },
  {
    icon: <Globe className="w-6 h-6" />,
    title: "Global Access",
    description: "Built on Base Blockchain ensuring high scalability and low transaction costs.",
    color: "from-purple-500/20 to-purple-600/20"
  }
];

const benefits: Benefit[] = [
  {
    icon: <GraduationCap className="w-8 h-8 text-orange-400" />,
    title: "Financial Inclusion",
    description: "Breaking down barriers to provide universal access to financial services and opportunities."
  },
  {
    icon: <LineChart className="w-8 h-8 text-orange-400" />,
    title: "Economic Empowerment",
    description: "Enabling sustainable income generation through community participation and marketplace activity."
  },
  {
    icon: <ShieldCheck className="w-8 h-8 text-orange-400" />,
    title: "Community Governance",
    description: "Empowering token holders to shape platform policies and drive community initiatives."
  }
];

const AboutSection: React.FC = () => {
  return (
    <section className="relative py-20 bg-slate-950 overflow-hidden" id="about">
      {/* Background Effects */}
      <div className="absolute inset-0">
        <div className="absolute w-[500px] h-[500px] bg-orange-500/5 rounded-full blur-3xl -top-48 -left-48" />
        <div className="absolute w-[500px] h-[500px] bg-blue-500/5 rounded-full blur-3xl -bottom-48 -right-48" />
      </div>

      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Section Header */}
        <div className="text-center max-w-3xl mx-auto mb-16">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5 }}
          >
            <span className="inline-block px-4 py-2 rounded-full bg-orange-500/10 border border-orange-500/20 text-orange-400 text-sm font-medium mb-4">
              About Scorpion World
            </span>
            <h2 className="text-4xl md:text-5xl font-bold mb-6">
              <span className="bg-gradient-to-r from-orange-400 via-orange-300 to-yellow-400 text-transparent bg-clip-text">
                Financial Inclusion Through Innovation
              </span>
            </h2>
            <p className="text-blue-100/60 text-lg">
              Empowering global financial inclusion through decentralized innovation, providing universal income, and enabling economic empowerment for individuals worldwide.
            </p>
          </motion.div>
        </div>

        {/* Features Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-20">
          {features.map((feature, index) => (
            <motion.div
              key={feature.title}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
              className="relative group"
            >
              <div className={`absolute inset-0 bg-gradient-to-br ${feature.color} rounded-2xl blur-xl transition-all duration-300 group-hover:blur-2xl`} />
              <div className="relative bg-blue-900/40 backdrop-blur-xl rounded-2xl p-6 h-full border border-blue-800/30">
                <div className="flex flex-col h-full">
                  <div className="text-orange-400 mb-4">{feature.icon}</div>
                  <h3 className="text-xl font-bold text-blue-100 mb-2">{feature.title}</h3>
                  <p className="text-blue-100/60 text-sm flex-grow">{feature.description}</p>
                </div>
              </div>
            </motion.div>
          ))}
        </div>

        {/* Content Sections */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
          {/* Left Column - Image/Visual */}
          <motion.div
            initial={{ opacity: 0, x: -50 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5 }}
            className="relative"
          >
            <div className="relative aspect-square max-w-md mx-auto">
              <div className="absolute inset-0 flex items-center justify-center">
                <motion.div
                  className="absolute w-full h-full bg-orange-400/10 rounded-full blur-3xl"
                  animate={{
                    scale: [1, 1.2, 1],
                    opacity: [0.3, 0.6, 0.3],
                  }}
                  transition={{
                    duration: 4,
                    repeat: Infinity,
                    ease: "easeInOut",
                  }}
                />
                <img
                  src={scorp}
                  alt="Scorpion World Visual"
                  className="relative w-64 h-64 rounded-full z-10"
                />
              </div>

              {/* Stats Overlays */}
              {[
                { label: "Communities Served", value: "100+", position: "top-12 -right-8" },
                { label: "Active Users", value: "250K+", position: "bottom-12 -left-8" },
              ].map((stat, index) => (
                <motion.div
                  key={stat.label}
                  initial={{ opacity: 0, scale: 0.8 }}
                  whileInView={{ opacity: 1, scale: 1 }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.5, delay: 0.2 + index * 0.1 }}
                  className={`absolute ${stat.position} bg-blue-900/40 backdrop-blur-xl rounded-xl p-3 border border-blue-800/30`}
                >
                  <p className="text-xs text-blue-100/60">{stat.label}</p>
                  <p className="text-sm font-bold text-blue-100">{stat.value}</p>
                </motion.div>
              ))}
            </div>
          </motion.div>

          {/* Right Column - Benefits */}
          <motion.div
            initial={{ opacity: 0, x: 50 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5 }}
            className="space-y-8"
          >
            <div className="space-y-6">
              {benefits.map((benefit, index) => (
                <motion.div
                  key={benefit.title}
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.5, delay: 0.2 + index * 0.1 }}
                  className="flex gap-4"
                >
                  <div className="flex-shrink-0 w-12 h-12 bg-blue-900/40 rounded-xl flex items-center justify-center border border-blue-800/30">
                    {benefit.icon}
                  </div>
                  <div>
                    <h3 className="text-xl font-bold text-blue-100 mb-2">{benefit.title}</h3>
                    <p className="text-blue-100/60">{benefit.description}</p>
                  </div>
                </motion.div>
              ))}
            </div>

            {/* CTA Button */}
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: 0.5 }}
            >
              <a
                href="https://t.me/scorpioncommunity_channel"
                className="inline-flex items-center gap-2 px-6 py-3 bg-gradient-to-r from-orange-500 to-orange-600 
                  text-white font-bold rounded-xl hover:opacity-90 transition-opacity group"
              >
                Join Our Community
                <ArrowUpRight className="w-4 h-4 group-hover:translate-x-0.5 group-hover:-translate-y-0.5 transition-transform" />
              </a>
            </motion.div>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default AboutSection;