import AboutSection from '../components/AboutSection';
import FaqSection from '../components/FaqSection';
import Footer from '../components/FooterSection';
import HeroSection from '../components/HeroSection';
import Navbar from '../components/NavBar';
import RoadmapSection from '../components/RoadmapSection';
import TokenomicsSection from '../components/TokenomicsSection';
import TokenPurchaseCard from '../components/TokenPurchaseCard';

const Home = () => {
  return (
    <>
  <Navbar/>
  <HeroSection/> 
  <AboutSection/>
  <TokenomicsSection/>
  {/* <TokenPurchaseCard /> */}
  <RoadmapSection/>
  <FaqSection/>
  <Footer/>
  </>
  );
};

export default Home;