// App.js
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import PresentationCard from './pages/PresentationCard';
import Whitelist from './pages/Whitelist';



function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />  
        <Route path="/tokenomics" element={<PresentationCard />} />  {/* Dashboard page */}        {/* Home page */}
        <Route path="/whitelist" element={<Whitelist />} />  {/* Dashboard page */}        {/* Home page */}
      </Routes>
    </Router>
  );
}

export default App;
