import React, { useState, useEffect, ReactNode } from 'react';
import { 
  ChevronLeft, 
  ChevronRight, 
  Crown, 
  Gem, 
  Shield, 
  Coins, 
  Users, 
  LineChart, 
  Target,
  Building,
  Award,
  Wallet,
  Hand,
  Gift,
  Sparkles,
  Lock,
  HeartHandshake,
  Check,
  BarChart2
} from 'lucide-react';

// Types
interface IconWrapperProps {
  children: ReactNode;
  animate?: boolean;
}

interface AnimatedCardProps {
  children: ReactNode;
  delay?: number;
}

interface FeatureCardProps {
  icon: ReactNode;
  title: string;
  description: string;
  delay?: number;
}

interface Slide {
  title: string;
  icon: ReactNode;
  subtitle?: string;
  content: JSX.Element;
}

interface TableProps {
  headers: string[];
  rows: (string | number)[][];
}

// Constants
const SLIDE_TRANSITION_DURATION = 500;

const PremiumGradients = {
  background: "bg-gradient-to-br from-gray-900 via-gray-800 to-blue-900",
  card: "bg-gradient-to-br from-gray-800 via-gray-900 to-gray-800",
  accent: "bg-gradient-to-r from-amber-500 via-yellow-500 to-orange-500",
  text: "text-transparent bg-clip-text bg-gradient-to-r from-amber-500 via-yellow-500 to-orange-500",
} as const;

// Reusable Components
const IconWrapper: React.FC<IconWrapperProps> = ({ children, animate = false }) => (
  <div className={`p-3 rounded-full bg-gradient-to-r from-amber-500 to-orange-500 shadow-lg
    ${animate ? 'hover:scale-110 transition-transform duration-300' : ''}`}>
    {children}
  </div>
);

const AnimatedCard: React.FC<AnimatedCardProps> = ({ children, delay = 0 }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setIsVisible(true), delay);
    return () => clearTimeout(timer);
  }, [delay]);

  return (
    <div className={`transform transition-all duration-700 
      ${isVisible ? 'translate-y-0 opacity-100' : 'translate-y-10 opacity-0'}`}>
      {children}
    </div>
  );
};

const FeatureCard: React.FC<FeatureCardProps> = ({ icon, title, description, delay = 0 }) => (
  <AnimatedCard delay={delay}>
    <div className="bg-gray-800/50 p-6 rounded-lg border border-amber-500/20 hover:border-amber-500/40 
      transition-colors duration-300">
      <div className="flex items-center gap-4 mb-4">
        <IconWrapper>{icon}</IconWrapper>
        <h3 className={`font-bold text-xl ${PremiumGradients.text}`}>{title}</h3>
      </div>
      <p className="text-gray-300">{description}</p>
    </div>
  </AnimatedCard>
);

const Table: React.FC<TableProps> = ({ headers, rows }) => (
  <div className="overflow-x-auto">
    <table className="w-full border-collapse">
      <thead>
        <tr className="border-b border-amber-500/20">
          {headers.map((header, index) => (
            <th key={index} className={`p-4 text-left ${PremiumGradients.text} font-bold`}>
              {header}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {rows.map((row, index) => (
          <tr key={index} className="border-b border-amber-500/10 hover:bg-amber-500/5 transition-colors">
            {row.map((cell, cellIndex) => (
              <td key={cellIndex} className="p-4 text-gray-300">
                {cell}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);

// Main Component
const PresentationCard: React.FC = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [direction, setDirection] = useState<'next' | 'prev'>('next');

  const handleSlideChange = (newSlide: number) => {
    setDirection(newSlide > currentSlide ? 'next' : 'prev');
    setIsTransitioning(true);
    setTimeout(() => {
      setCurrentSlide(newSlide);
      setIsTransitioning(false);
    }, SLIDE_TRANSITION_DURATION);
  };

  const slides: Slide[] = [
    {
      title: "Key Features",
      icon: <Award size={32} className="text-white" />,
      content: (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <FeatureCard
            icon={<Gift className="text-white" size={24} />}
            title="Universal Income"
            description="Strategic SCORP token distributions establishing a sustainable financial foundation for participants."
          />
          <FeatureCard
            icon={<Coins className="text-white" size={24} />}
            title="Marketplace Utility"
            description="SCORP serves as the cornerstone currency within our sophisticated digital ecosystem."
            delay={200}
          />
          <FeatureCard
            icon={<Users className="text-white" size={24} />}
            title="Community Governance"
            description="Token holders shape platform evolution through democratic decision-making processes."
            delay={300}
          />
          <FeatureCard
            icon={<Hand className="text-white" size={24} />}
            title="Proof of Community"
            description="Revolutionary mining protocol rewarding meaningful community engagement and participation."
            delay={400}
          />
        </div>
      )
    },
    {
      title: "SCORP Tokenomics",
      icon: <BarChart2 size={32} className="text-white" />,
      content: (
        <AnimatedCard>
          <div className="space-y-6">
            <div className="bg-gray-800/50 p-6 rounded-lg border border-amber-500/20">
              <h3 className={`text-2xl font-bold ${PremiumGradients.text} mb-6`}>
                Total Supply: 150,000,000 SCORP
              </h3>
              <Table
                headers={["Allocation", "Percentage", "Tokens"]}
                rows={[
                  ["Universal Income & Rewards", "40%", "60,000,000"],
                  ["Development & Operations", "25%", "37,500,000"],
                  ["Community Governance", "15%", "22,500,000"],
                  ["Liquidity & Partnerships", "10%", "15,000,000"],
                  ["Strategic Reserve", "10%", "15,000,000"]
                ]}
              />
            </div>
          </div>
        </AnimatedCard>
      )
    },
    {
      title: "Token Distribution Rounds",
      icon: <Wallet size={32} className="text-white" />,
      content: (
        <AnimatedCard>
          <div className="space-y-6">
            <div className="bg-gray-800/50 p-6 rounded-lg border border-amber-500/20">
              <Table
                headers={["Round", "Price (USD)", "Allocation", "Total Value"]}
                rows={[
                  ["Foundation Round", "$0.002", "15M SCORP", "$30,000"],
                  ["Strategic Round 1", "$0.005", "10M SCORP", "$50,000"],
                  ["Strategic Round 2", "$0.01", "10M SCORP", "$100,000"],
                  ["Community Round 1", "$0.02", "15M SCORP", "$300,000"],
                  ["Community Round 2", "$0.05", "15M SCORP", "$750,000"],
                  ["Community Round 3", "$0.10", "10M SCORP", "$1,000,000"],
                  ["Pre-Launch Phase", "$0.15", "5M SCORP", "$750,000"]
                ]}
              />
              <p className={`text-xl font-bold ${PremiumGradients.text} mt-4`}>
                Projected Launch Value: $0.20 per SCORP
              </p>
            </div>
          </div>
        </AnimatedCard>
      )
    },
    {
      title: "Founder's Commitment",
      icon: <HeartHandshake size={32} className="text-white" />,
      content: (
        <div className="space-y-6">
          <FeatureCard
            icon={<Crown className="text-white" size={24} />}
            title="Executive Commitment"
            description="15% of Mr. Scorpion's annual revenue allocated to ecosystem development and holder benefits."
          />
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-6">
            <FeatureCard
              icon={<Gift className="text-white" size={24} />}
              title="Holder Benefits"
              description="Strategic distribution of rewards to SCORP token holders."
              delay={200}
            />
            <FeatureCard
              icon={<Building className="text-white" size={24} />}
              title="Ecosystem Growth"
              description="Sustained investment in platform development and ecosystem expansion."
              delay={300}
            />
          </div>
        </div>
      )
    },
    {
      title: "Why Hold SCORP?",
      icon: <Gem size={32} className="text-white" />,
      content: (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <FeatureCard
            icon={<LineChart className="text-white" size={24} />}
            title="Value Proposition"
            description="Strategic entry from $0.002 to $0.20 projected launch value - substantial growth potential."
          />
          <FeatureCard
            icon={<Lock className="text-white" size={24} />}
            title="Early Participation"
            description="Pioneer access to an innovative financial ecosystem driving global change."
            delay={200}
          />
          <FeatureCard
            icon={<Check className="text-white" size={24} />}
            title="Established Leadership"
            description="Expert team with demonstrated excellence in blockchain and financial technologies."
            delay={300}
          />
          <FeatureCard
            icon={<Users className="text-white" size={24} />}
            title="Thriving Community"
            description="Join an expanding global network of visionaries and contributors."
            delay={400}
          />
        </div>
      )
    }
  ];

  return (
    <div className={`min-h-screen ${PremiumGradients.background} p-4`}>
      <div className="max-w-6xl mx-auto">
        <header className="text-center mb-12 pt-8">
          <div className="flex justify-center mb-6">
            <IconWrapper animate={true}>
              <Crown size={48} className="text-white" />
            </IconWrapper>
          </div>
          <h1 className="text-5xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-amber-500 via-yellow-500 to-orange-500 mb-3">
            Scorpion World
          </h1>
          <p className="text-xl text-blue-400">Pioneering the Future of Decentralized Finance</p>
        </header>

        <div className={`${PremiumGradients.card} rounded-xl shadow-2xl p-8 border border-amber-500/20`}>
          <div className="flex justify-between items-center mb-8">
            <div className="flex items-center gap-4">
              {slides[currentSlide].icon}
              <h2 className="text-2xl font-bold text-amber-500">{slides[currentSlide].title}</h2>
            </div>
            <div className="text-sm text-gray-400">
              {currentSlide + 1} of {slides.length}
            </div>
          </div>

          <div className={`min-h-96 transition-all duration-500 
            ${isTransitioning ? (direction === 'next' ? 'translate-x-full opacity-0' : '-translate-x-full opacity-0') : 'translate-x-0 opacity-100'}`}>
            {slides[currentSlide].content}
          </div>

          <div className="flex justify-between items-center mt-8">
            <button 
              onClick={() => handleSlideChange((currentSlide - 1 + slides.length) % slides.length)}
              className="p-3 rounded-full bg-gradient-to-r from-amber-500 to-orange-500 hover:from-amber-600 hover:to-orange-600 transition-all duration-300 disabled:opacity-50 transform hover:scale-105"
              disabled={isTransitioning}
            >
              <ChevronLeft className="w-6 h-6 text-white" />
            </button>

            <div className="flex gap-3">
              {slides.map((_, index) => (
                <button
                  key={index}
                  onClick={() => handleSlideChange(index)}
                  className={`w-3 h-3 rounded-full transition-all duration-300 transform hover:scale-125${currentSlide === index ? 'bg-gradient-to-r from-amber-500 to-orange-500' : 'bg-gray-600 hover:bg-gray-500'}`}
                  />
                ))}
              </div>
  
              <button
                onClick={() => handleSlideChange((currentSlide + 1) % slides.length)}
                className="p-3 rounded-full bg-gradient-to-r from-amber-500 to-orange-500 hover:from-amber-600 hover:to-orange-600 transition-all duration-300 disabled:opacity-50 transform hover:scale-105"
                disabled={isTransitioning}
              >
                <ChevronRight className="w-6 h-6 text-white" />
              </button>
            </div>
          </div>
  
          <footer className="text-center mt-8 text-gray-400 text-sm">
            <p>© 2024 Scorpion World. All rights reserved.</p>
          </footer>
        </div>
      </div>
    );
  };
  
  export default PresentationCard;