import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { 
  ChevronDown, 
  HelpCircle,
  ArrowUpRight,
  Shield,
  Coins,
  MessageCircle,
  Users,
  Building2
} from 'lucide-react';

// Types
interface FaqCategory {
  name: string;
  icon: React.ReactNode;
  description: string;
}

interface FaqItem {
  question: string;
  answer: string;
  category: string;
}

// FAQ Categories
const categories: FaqCategory[] = [
  {
    name: "General",
    icon: <HelpCircle className="w-5 h-5" />,
    description: "About Scorpion World ecosystem"
  },
  {
    name: "Universal Income",
    icon: <Coins className="w-5 h-5" />,
    description: "Distribution and benefits"
  },
  {
    name: "Mining & Community",
    icon: <Users className="w-5 h-5" />,
    description: "PoC mining and participation"
  },
  {
    name: "Ecosystem",
    icon: <Building2 className="w-5 h-5" />,
    description: "Platform features and benefits"
  }
];

// Updated FAQ Data aligned with whitepaper
const faqData: FaqItem[] = [
  {
    category: "General",
    question: "What is Scorpion World?",
    answer: "Scorpion World is a decentralized platform built on blockchain technology that fosters global financial inclusion, provides universal income, and enables economic empowerment through innovative features and community governance."
  },
  {
    category: "General",
    question: "What is the SCORP token?",
    answer: "SCORP is the native token of the Scorpion World ecosystem, built on the Base Blockchain. It facilitates universal income distribution, marketplace transactions, and community governance while ensuring high scalability and low transaction costs."
  },
  {
    category: "Universal Income",
    question: "How does Universal Income work?",
    answer: "Universal Income is distributed through the SCORP token on a regular basis to qualified participants. The amount is calculated based on base rate, activity multiplier, and staking bonus, providing a reliable financial foundation for all community members."
  },
  {
    category: "Universal Income",
    question: "What are the qualification criteria?",
    answer: "Qualification is based on active participation in the ecosystem, including marketplace activity, community engagement, and staking. The system is designed to reward consistent and meaningful contribution to the platform."
  },
  {
    category: "Mining & Community",
    question: "What is Proof of Community (PoC) mining?",
    answer: "PoC is our sustainable mining model where users earn SCORP tokens through active ecosystem participation rather than computational power. This approach ensures environmental responsibility while rewarding community contribution."
  },
  {
    category: "Mining & Community",
    question: "How can I participate in mining?",
    answer: "You can participate by engaging in community activities, contributing to platform development, participating in governance, and maintaining active involvement in the marketplace. No specialized hardware is required."
  },
  {
    category: "Ecosystem",
    question: "What is the Scorpion World Marketplace?",
    answer: "The marketplace is a decentralized platform where users can exchange goods and services using SCORP tokens. It features secure transactions, community ratings, and diverse offerings to support economic activity."
  },
  {
    category: "Ecosystem",
    question: "How does governance work?",
    answer: "SCORP holders can participate in platform governance through voting rights. This includes deciding on platform updates, community initiatives, and resource allocation, ensuring democratic decision-making."
  },
  {
    category: "Universal Income",
    question: "What is the distribution schedule?",
    answer: "Universal Income is distributed on a regular basis following our systematic approach: daily base distributions, weekly community rewards, and monthly governance incentives."
  },
  {
    category: "Mining & Community",
    question: "What are the rewards for community participation?",
    answer: "Rewards include increased Universal Income multipliers, governance voting power, marketplace benefits, and special access to community initiatives and development opportunities."
  },
  {
    category: "Ecosystem",
    question: "What makes Scorpion World sustainable?",
    answer: "Our sustainability is built on three pillars: environmental responsibility through PoC mining, economic stability through tokenomics, and community-driven development ensuring long-term growth."
  },
  {
    category: "Ecosystem",
    question: "How is platform security ensured?",
    answer: "Security is maintained through Base Blockchain integration, smart contract audits, multi-layered security protocols, and continuous monitoring systems. Community governance also plays a role in security decisions."
  }
];

const FaqSection: React.FC = () => {
  const [activeCategory, setActiveCategory] = useState<string>("General");
  const [expandedItems, setExpandedItems] = useState<number[]>([]);

  const toggleItem = (index: number) => {
    setExpandedItems(prev => 
      prev.includes(index) 
        ? prev.filter(i => i !== index)
        : [...prev, index]
    );
  };

  const filteredFaqs = faqData.filter(faq => faq.category === activeCategory);

  return (
    <section className="relative py-20 bg-slate-950 overflow-hidden" id="faq">
      {/* Background Effects */}
      <div className="absolute inset-0">
        <div className="absolute w-[500px] h-[500px] bg-orange-500/5 rounded-full blur-3xl -top-48 -left-48" />
        <div className="absolute w-[500px] h-[500px] bg-blue-500/5 rounded-full blur-3xl -bottom-48 -right-48" />
      </div>

      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Section Header */}
        <div className="text-center max-w-3xl mx-auto mb-16">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5 }}
          >
            <span className="inline-block px-4 py-2 rounded-full bg-orange-500/10 border border-orange-500/20 text-orange-400 text-sm font-medium mb-4">
              FAQ
            </span>
            <h2 className="text-4xl md:text-5xl font-bold mb-6">
              <span className="bg-gradient-to-r from-orange-400 via-orange-300 to-yellow-400 text-transparent bg-clip-text">
                Frequently Asked Questions
              </span>
            </h2>
            <p className="text-blue-100/60 text-lg">
              Learn more about Scorpion World's ecosystem and universal income platform
            </p>
          </motion.div>
        </div>

        {/* Category Navigation */}
        <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mb-12">
          {categories.map((category, index) => (
            <motion.button
              key={category.name}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
              onClick={() => setActiveCategory(category.name)}
              className={`p-4 rounded-xl border transition-all duration-300 ${
                activeCategory === category.name
                  ? 'bg-orange-500/10 border-orange-500/20 text-orange-400'
                  : 'bg-blue-900/20 border-blue-800/30 text-blue-100 hover:bg-blue-900/30'
              }`}
            >
              <div className="flex flex-col items-center text-center">
                {category.icon}
                <span className="font-medium mt-2">{category.name}</span>
                <span className="text-xs mt-1 opacity-60">{category.description}</span>
              </div>
            </motion.button>
          ))}
        </div>

        {/* FAQ Items */}
        <div className="max-w-3xl mx-auto">
          <AnimatePresence mode="wait">
            <motion.div
              key={activeCategory}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.3 }}
              className="space-y-4"
            >
              {filteredFaqs.map((faq, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.5, delay: index * 0.1 }}
                  className="rounded-xl overflow-hidden"
                >
                  <button
                    onClick={() => toggleItem(index)}
                    className="w-full p-6 flex items-center justify-between bg-blue-900/20 
                      text-left border border-blue-800/30 rounded-xl hover:bg-blue-900/30 
                      transition-colors duration-300"
                  >
                    <span className="font-medium text-blue-100">{faq.question}</span>
                    <motion.div
                      animate={{ rotate: expandedItems.includes(index) ? 180 : 0 }}
                      transition={{ duration: 0.3 }}
                    >
                      <ChevronDown className="w-5 h-5 text-blue-100/60" />
                    </motion.div>
                  </button>
                  
                  <AnimatePresence>
                    {expandedItems.includes(index) && (
                      <motion.div
                        initial={{ height: 0, opacity: 0 }}
                        animate={{ height: "auto", opacity: 1 }}
                        exit={{ height: 0, opacity: 0 }}
                        transition={{ duration: 0.3 }}
                        className="border-x border-b border-blue-800/30 rounded-b-xl"
                      >
                        <div className="p-6 bg-blue-900/10">
                          <p className="text-blue-100/60">{faq.answer}</p>
                        </div>
                      </motion.div>
                    )}
                  </AnimatePresence>
                </motion.div>
              ))}
            </motion.div>
          </AnimatePresence>
        </div>

        {/* Support Section */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.5 }}
          className="mt-20 text-center"
        >
          <div className="max-w-2xl mx-auto">
            <h3 className="text-xl font-bold text-blue-100 mb-4">
              Still have questions?
            </h3>
            <p className="text-blue-100/60 mb-8">
              Join our community or reach out to our support team for assistance
            </p>
            <div className="flex flex-col sm:flex-row gap-4 justify-center">
              <a
                href="https://t.me/scorpioncommunity_channel"
                target="_blank"
                rel="noopener noreferrer"
                className="inline-flex items-center justify-center gap-2 px-6 py-3 
                  bg-blue-900/20 text-blue-100 rounded-xl hover:bg-blue-900/30 
                  transition-colors border border-blue-800/30 group"
              >
                <MessageCircle className="w-5 h-5" />
                Join Community
                <ArrowUpRight className="w-4 h-4 group-hover:translate-x-0.5 
                  group-hover:-translate-y-0.5 transition-transform" />
              </a>
              <a
                href="mailto:contact@scorpionworldtoken.xyz"
                className="inline-flex items-center justify-center gap-2 px-6 py-3 
                  bg-orange-500 text-white rounded-xl hover:opacity-90 
                  transition-opacity group"
              >
                <Shield className="w-5 h-5" />
                Technical Support
                <ArrowUpRight className="w-4 h-4 group-hover:translate-x-0.5 
                  group-hover:-translate-y-0.5 transition-transform" />
              </a>
            </div>
          </div>
        </motion.div>
      </div>
    </section>
  );
};

export default FaqSection;