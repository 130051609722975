// components/NavbarWithTokenSale.tsx
import React, { useState, useEffect, useMemo } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { 
  ArrowUpRight, 
  Menu, 
  X, 
  ChevronRight,
  LogOut,
  Wallet,
  Clock,
  Timer,
  AlertCircle,
  Shield,
  BarChart3,
  CreditCard,
  HelpCircle,
  CheckCircle,
  Lock
} from 'lucide-react';
import {
  ConnectWallet,
  useAddress,
  useDisconnect,
  useContract,
  useTokenBalance,
  useTokenSupply,
  useContractMetadata,
  useActiveClaimConditionForWallet,
  useClaimConditions,
  useClaimerProofs,
  useClaimIneligibilityReasons,
  Web3Button
} from '@thirdweb-dev/react';
import { BigNumber, utils } from 'ethers';
import Countdown from 'react-countdown';
import { tokendropcontract } from '../const/ContractAddress';
import { scorp } from '../images';

// Types
interface NavItemData {
  href: string;
  label: string;
  emoji: string;
  hoverEmoji: string;
}

interface CountdownProps {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
  completed: boolean;
}

interface ButtonState {
  text: string;
  color: string;
  isDisabled: boolean;
}

// Constants
const TOKEN_SALE_CONFIG = {
  MAX_SUPPLY: 150_000_000,
  IDO_END_DATE: new Date('2024-11-30T12:00:00Z').getTime(),
  MANUAL_USD_RATE: 0.002,
  SOFT_CAP: 10000,
  HARD_CAP: 500000,
  DEFAULT_QUANTITY: 1000,
  PERCENTAGE_OPTIONS: [10, 25, 50, 100],
  ACCEPTED_CURRENCIES: ['ETH', 'BNB', 'USDT']
} as const;

const navItems: NavItemData[] = [
  { href: "#home", label: "Home", emoji: "🏠", hoverEmoji: "🌟" },
  { href: "#about", label: "About", emoji: "🦂", hoverEmoji: "💫" },
  { href: "#tokenomics", label: "Tokenomics", emoji: "📊", hoverEmoji: "📈" },
  { href: "#roadmap", label: "Roadmap", emoji: "🗺️", hoverEmoji: "🎯" },
];

// Helper Components
const LoadingSpinner: React.FC<{ size?: string; color?: string }> = ({ 
  size = "w-4 h-4", 
  color = "border-white" 
}) => (
  <motion.div
    className={`${size} border-2 ${color} border-t-transparent rounded-full`}
    animate={{ rotate: 360 }}
    transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
  />
);

// Token Balance Component
const TokenBalance: React.FC<{ isMobile?: boolean }> = ({ isMobile = false }) => {
  const address = useAddress();
  const { contract } = useContract(tokendropcontract);
  const { data: balance, isLoading } = useTokenBalance(contract, address);
  const { data: activeClaimCondition } = useActiveClaimConditionForWallet(contract, address);

  if (!address || isLoading) return null;

  const formattedBalance = balance ? Number(balance.displayValue).toLocaleString() : '0';
  const usdValue = balance ? 
    (Number(balance.displayValue) * TOKEN_SALE_CONFIG.MANUAL_USD_RATE).toFixed(2) : '0.00';

  return (
    <motion.div 
      className={`hidden lg:flex items-center gap-2 px-3 py-3 bg-blue-900/20 
        rounded-xl border border-blue-800/30 ${isMobile ? 'w-full' : 'hidden lg:flex'}`}
      whileHover={{ scale: 1.02 }}
    >
      <Wallet className="w-4 h-4 text-orange-400" />
      <div className="flex flex-col">
        <span className="text-xs font-medium text-blue-100 whitespace-nowrap">
          {formattedBalance} $SCORP
        </span>
        {/* <span className="text-[10px] text-blue-100/60">
          ≈ ${usdValue}
        </span> */}
      </div>
    </motion.div>
  );
};

// Sale Progress Component
const SaleProgress: React.FC<{ isMobile?: boolean }> = ({ isMobile = false }) => {
  const { contract } = useContract(tokendropcontract);
  const { data: tokenSupply } = useTokenSupply(contract);
  const [currentProgress, setCurrentProgress] = useState<number>(0);

  useEffect(() => {
    if (tokenSupply?.displayValue) {
      const calculatedPercentage = (parseFloat(tokenSupply.displayValue) / TOKEN_SALE_CONFIG.MAX_SUPPLY) * 100;
      setCurrentProgress(Math.min(calculatedPercentage, 100));
    }
  }, [tokenSupply]);

  const remainingDays = Math.ceil(
    (TOKEN_SALE_CONFIG.IDO_END_DATE - Date.now()) / (1000 * 60 * 60 * 24)
  );

  const baseClasses = isMobile
    ? "w-full p-4 bg-blue-900/20 rounded-xl border border-blue-800/30"
    : "hidden lg:flex items-center gap-4 px-4 py-2 bg-blue-900/20 rounded-xl border border-blue-800/30";

  return (
    <div className={baseClasses}>
      <div className="flex items-center gap-2">
        <Clock className="w-4 h-4 text-orange-400" />
        <span className="text-xs text-blue-100">Sale Progress:</span>
        <span className="text-xs font-bold text-orange-400">{currentProgress.toFixed(1)}%</span>
      </div>
      <div className="w-24 h-1.5 bg-blue-900/40 rounded-full overflow-hidden">
        <motion.div
          className="h-full bg-gradient-to-r from-orange-500 to-orange-400"
          initial={{ width: 0 }}
          animate={{ width: `${currentProgress}%` }}
          transition={{ duration: 1 }}
        />
      </div>
      <div className="flex items-center gap-2 border-l border-blue-800/30 pl-4">
        <Timer className="w-4 h-4 text-orange-400" />
        <span className="text-xs text-blue-100">{remainingDays} days left</span>
      </div>
    </div>
  );
};

// Buy Button Component
const BuyButton: React.FC<{ isMobile?: boolean }> = ({ isMobile = false }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <motion.a
      href="#buy"
      className={`flex items-center justify-center gap-2 px-4 py-2 
        bg-gradient-to-r from-orange-500 to-orange-400 text-blue-950 
        font-bold rounded-xl shadow-lg group relative overflow-hidden
        ${isMobile ? 'w-full' : 'hidden xl:flex'}`}
      whileHover={{ scale: 1.02 }}
      whileTap={{ scale: 0.98 }}
      onHoverStart={() => setIsHovered(true)}
      onHoverEnd={() => setIsHovered(false)}
    >
      <span className="flex items-center gap-1.5 relative z-10">
        <motion.span
          animate={{ rotate: isHovered ? 360 : 0 }}
          transition={{ duration: 0.5 }}
        >
          🚀
        </motion.span>
        <span className="text-sm">Buy $SCORP</span>
        <ArrowUpRight className="w-4 h-4 group-hover:translate-x-0.5 
          group-hover:-translate-y-0.5 transition-transform" />
      </span>
      
      <motion.div
        className="absolute inset-0 bg-gradient-to-r from-orange-400/20 to-yellow-400/20"
        initial={{ x: '-100%' }}
        animate={isHovered ? { x: '100%' } : { x: '-100%' }}
        transition={{ duration: 1 }}
      />
    </motion.a>
  );
};

// Wallet Status Component
const WalletStatus: React.FC = () => {
  const address = useAddress();
  const disconnect = useDisconnect();
  const [isHovered, setIsHovered] = useState(false);

  if (!address) return null;

  return (
    <motion.div
      className="hidden lg:flex items-center gap-2 px-3 py-2 bg-blue-900/20 
        rounded-xl border border-blue-800/30"
      whileHover={{ scale: 1.02 }}
    >
      <div className="flex items-center gap-2">
        <div className="w-1.5 h-1.5 bg-green-400 rounded-full animate-pulse" />
        <span className="text-xs text-blue-100 whitespace-nowrap">
          {`${address.slice(0, 6)}...${address.slice(-4)}`}
        </span>
      </div>
      
      <button
        onClick={() => disconnect()}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        className="p-1 hover:bg-red-500/10 rounded-lg transition-colors"
      >
        <LogOut 
          className={`w-3.5 h-3.5 ${isHovered ? 'text-red-400' : 'text-blue-400'}`}
        />
      </button>
    </motion.div>
  );
};

// Main Component
const NavbarWithTokenSale: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const address = useAddress();
  const disconnect = useDisconnect();

  // Contract Hooks
  const { contract } = useContract(tokendropcontract);
  const { data: tokenSupply } = useTokenSupply(contract);
  const { data: contractMetadata } = useContractMetadata(contract);
  const activeClaimCondition = useActiveClaimConditionForWallet(contract, address);

  // Scroll Effect
  useEffect(() => {
    const handleScroll = () => setScrolled(window.scrollY > 20);
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Mobile Menu Effects
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 768) setIsOpen(false);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    document.body.style.overflow = isOpen ? 'hidden' : 'unset';
    return () => { document.body.style.overflow = 'unset'; };
  }, [isOpen]);

  // Calculate sale progress
  const currentProgress = useMemo(() => {
    if (!tokenSupply?.displayValue) return 0;
    return Math.min(
      (parseFloat(tokenSupply.displayValue) / TOKEN_SALE_CONFIG.MAX_SUPPLY) * 100,
      100
    );
  }, [tokenSupply]);

  return (
    <>
      {/* Mobile Menu Overlay */}
      <AnimatePresence>
        {isOpen && (
          <motion.div
            className="fixed inset-0 bg-slate-950/80 backdrop-blur-xl z-40 md:hidden"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={() => setIsOpen(false)}
          />
        )}
      </AnimatePresence>

      <nav className={`fixed top-0 left-0 right-0 z-50 transition-all duration-300 ${
        scrolled 
          ? 'bg-slate-950/90 backdrop-blur-xl border-b border-blue-800/30 shadow-lg' 
          : 'bg-transparent'
      }`}>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          {/* Main Navbar Content */}
          <div className="flex items-center justify-between h-16 sm:h-18">
            {/* Logo and Navigation */}
            <div className="flex items-center">
              {/* Logo */}
              <motion.a 
                href="#"
                className="flex-shrink-0 flex items-center gap-2.5 py-2"
                whileHover={{ scale: 1.02 }}
              >
                <div className="relative">
                  <motion.div
                    className="absolute inset-0 bg-orange-400/40 rounded-xl blur-md"
                    animate={{
                      scale: [1, 1.2, 1],
                      opacity: [0.5, 0.8, 0.5],
                    }}
                    transition={{
                      duration: 2,
                      repeat: Infinity,
                      ease: "easeInOut",
                    }}
                  />
                  <img
                    src={scorp}
                    alt="$SCORP Logo"
                    className="relative w-8 h-8 sm:w-9 sm:h-9 rounded-xl"
                  />
                </div>
                <div className="flex flex-col">
                  <span className="text-base sm:text-lg font-bold bg-gradient-to-r 
                    from-orange-400 to-orange-300 text-transparent bg-clip-text">
                   Scorpion World
                  </span>
                </div>
              </motion.a>

              {/* Desktop Navigation */}
              <div className="hidden md:flex items-center gap-2 ml-6">
                {navItems.map((item) => (
                  <NavLink key={item.href} {...item} />
                ))}
              </div>
            </div>

            {/* Desktop Right Section */}
            <div className="hidden md:flex items-center gap-2">
              {/* <SaleProgress /> */}
              {address && <TokenBalance />}
              <WalletStatus />
              {!address ? (
                <ConnectWallet
                  theme="dark"
                  btnTitle="Connect Wallet"
                  className="!bg-gradient-to-r from-orange-500 to-orange-600 !text-white !px-4 !py-2 font-bold !rounded-xl 
                  transition-all hover:opacity-90"
                />
              ) : (
                <BuyButton />
              )}
            </div>

            {/* Mobile Menu Button */}
            <motion.button
              onClick={() => setIsOpen(!isOpen)}
              className="inline-flex md:hidden items-center justify-center p-2 
                rounded-xl text-orange-400 hover:bg-blue-900/20"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              <span className="sr-only">Toggle menu</span>
              {isOpen ? (
                <X className="w-6 h-6" />
              ) : (
                <Menu className="w-6 h-6" />
              )}
            </motion.button>
          </div>

          {/* Mobile Menu */}
          <AnimatePresence>
            {isOpen && (
              <motion.div
                className="fixed inset-x-0 top-16 h-[calc(100vh-4rem)] bg-slate-950/95 
                  backdrop-blur-xl md:hidden z-50 overflow-y-auto"
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -10 }}
                transition={{ duration: 0.2 }}
              >
                <div className="p-4">
                  {/* Mobile Navigation Links */}
                  <div className="space-y-2 mb-6">
                    {navItems.map((item) => (
                      <NavLink
                        key={item.href}
                        {...item}
                        onClick={() => setIsOpen(false)}
                        isMobile
                      />
                    ))}
                  </div>

                  {/* Mobile Sale Progress */}
                  <div className="space-y-4 pt-4 border-t border-blue-800/30">
                    <SaleProgress isMobile />

                    {/* Mobile Token Stats */}
                    <div className="grid grid-cols-2 gap-4">
                      <div className="bg-blue-900/20 p-4 rounded-xl border border-blue-800/30">
                        <div className="text-xs text-blue-100/60 mb-1">Price</div>
                        <div className="text-sm font-bold text-orange-400">
                          ${TOKEN_SALE_CONFIG.MANUAL_USD_RATE}
                        </div>
                      </div>
                      <div className="bg-blue-900/20 p-4 rounded-xl border border-blue-800/30">
                        <div className="text-xs text-blue-100/60 mb-1">Total Supply</div>
                        <div className="text-sm font-bold text-orange-400">
                          {(TOKEN_SALE_CONFIG.MAX_SUPPLY / 1_000_000).toFixed(1)}M
                        </div>
                      </div>
                    </div>

                    {/* Mobile Wallet Section */}
                    {address && (
                      <>
                        <TokenBalance isMobile />
                        <div className="flex items-center justify-between px-4 py-2 
                          bg-blue-900/20 rounded-xl border border-blue-800/30">
                          <div className="flex items-center gap-2">
                            <div className="w-2 h-2 bg-green-400 rounded-full animate-pulse" />
                            <span className="text-sm text-blue-100">
                              {`${address.slice(0, 6)}...${address.slice(-4)}`}
                            </span>
                          </div>
                          <button
                            onClick={() => disconnect()}
                            className="p-2 hover:bg-red-500/10 rounded-lg transition-colors"
                          >
                            <LogOut className="w-4 h-4 text-red-400" />
                          </button>
                        </div>
                      </>
                    )}

                    {/* Mobile Connect/Buy Button */}
                    {!address ? (
                      <div className="px-4">
                        <ConnectWallet
                          theme="dark"
                          btnTitle="Connect Wallet"
                          className="w-full !bg-gradient-to-r from-orange-500 to-orange-600 
                            !text-white font-bold !rounded-xl transition-all hover:opacity-90"
                        />
                      </div>
                    ) : (
                      <div className="px-4">
                        <BuyButton isMobile />
                      </div>
                    )}
                  </div>

                  {/* Mobile Additional Info */}
                  <div className="mt-6">
                    {/* Price Info */}
                    <motion.div
                      className="p-4 bg-blue-900/20 rounded-xl border border-blue-800/30"
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      transition={{ delay: 0.2 }}
                    >
                      <div className="flex items-center gap-2 text-sm text-blue-100">
                        <span className="text-orange-400">🔥</span>
                        <span>Current Phase</span>
                      </div>
                      <div className="mt-2 text-xs text-blue-100/60">
                        Early supporters get the best rates
                      </div>
                      <div className="mt-3 flex justify-between items-center text-sm">
                        <span className="text-blue-100/80">Rate:</span>
                        <span className="text-orange-400 font-medium">
                          1 ETH = 12,327,000 $SCORP
                        </span>
                      </div>
                    </motion.div>

                    {/* Sale Progress Details */}
                    <motion.div
                      className="mt-4 p-4 bg-blue-900/20 rounded-xl border border-blue-800/30"
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      transition={{ delay: 0.3 }}
                    >
                      <div className="flex justify-between items-center mb-2">
                        <span className="text-sm text-blue-100">Sale Progress</span>
                        <span className="text-sm text-orange-400 font-medium">
                          {currentProgress.toFixed(1)}%
                        </span>
                      </div>
                      <div className="w-full h-2 bg-blue-900/40 rounded-full overflow-hidden">
                        <motion.div
                          className="h-full bg-gradient-to-r from-orange-500 via-orange-400 to-orange-500"
                          initial={{ width: "0%" }}
                          animate={{ width: `${currentProgress}%` }}
                          transition={{ duration: 1, delay: 0.5 }}
                        />
                      </div>
                      <div className="mt-2 text-xs text-blue-100/60">
                        {tokenSupply?.displayValue 
                          ? Number(tokenSupply.displayValue).toLocaleString()
                          : '0'} / {TOKEN_SALE_CONFIG.MAX_SUPPLY.toLocaleString()} $SCORP sold
                      </div>
                    </motion.div>
                  </div>
                </div>
              </motion.div>
            )}
          </AnimatePresence>

          {/* Desktop Sale Info Banner */}
          {scrolled && (
            <motion.div
              className="hidden md:block absolute -bottom-10 left-1/2 transform -translate-x-1/2 
                whitespace-nowrap"
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -10 }}
            >
              <div className="px-4 py-2 bg-orange-500/10 rounded-full border border-orange-500/20">
                <div className="flex items-center gap-2 text-sm text-orange-400">
                  <span>🔥</span>
                  <span>Current Phase: </span>
                  <span className="font-bold">
                    1 ETH = 12,327,000 $SCORP
                  </span>
                  <span className="mx-2">•</span>
                  <span className="font-bold">{currentProgress.toFixed(1)}% Sold</span>
                </div>
              </div>
            </motion.div>
          )}

          {/* Progress bar with gradient background */}
          <div className="absolute bottom-0 left-0 right-0 h-0.5 bg-gradient-to-r 
            from-blue-900/20 via-blue-800/20 to-blue-900/20">
            <motion.div
              className="h-full bg-gradient-to-r from-orange-500 via-orange-400 to-orange-500"
              initial={{ width: "0%" }}
              animate={{ width: `${currentProgress}%` }}
              transition={{ duration: 1 }}
            />
          </div>
        </div>
      </nav>

      {/* Remove default input number spinners */}
      <style>{`
        input[type=number]::-webkit-inner-spin-button,
        input[type=number]::-webkit-outer-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
        input[type=number] {
          -moz-appearance: textfield;
        }
      `}</style>
    </>
  );
};

// Navigation Link Component
const NavLink: React.FC<{
  href: string;
  label: string;
  emoji: string;
  hoverEmoji: string;
  onClick?: () => void;
  isMobile?: boolean;
}> = ({ href, label, emoji, hoverEmoji, onClick, isMobile = false }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    const updateActive = () => {
      setIsActive(window.location.hash === href);
    };
    window.addEventListener('hashchange', updateActive);
    updateActive();
    return () => window.removeEventListener('hashchange', updateActive);
  }, [href]);

  const baseClasses = isMobile
    ? `flex items-center justify-between px-4 py-3 rounded-xl 
       text-base font-medium transition-colors`
    : `relative group px-3 py-2 rounded-xl text-sm font-medium 
       transition-all duration-300 hidden md:flex items-center`;

  const stateClasses = isActive
    ? 'text-orange-400 bg-blue-900/40'
    : 'text-blue-100 hover:text-orange-400 hover:bg-blue-900/20';

  return (
    <motion.a
      href={href}
      className={`${baseClasses} ${stateClasses}`}
      onClick={onClick}
      onHoverStart={() => setIsHovered(true)}
      onHoverEnd={() => setIsHovered(false)}
      whileHover={{ scale: 1.02 }}
      whileTap={{ scale: 0.98 }}
    >
      <span className="flex items-center gap-1.5">
        <motion.span
          initial={{ scale: 1 }}
          animate={{ scale: isHovered ? [1, 1.2, 1] : 1 }}
          transition={{ duration: 0.3 }}
        >
          {isHovered ? hoverEmoji : emoji}
        </motion.span>
        {label}
      </span>
      
      {!isMobile && (
        <motion.span
          className={`ml-1 opacity-50 transition-transform duration-300 ${
            isActive ? 'rotate-90' : 'group-hover:rotate-90'
          }`}
        >
          <ChevronRight className="w-3 h-3" />
        </motion.span>
      )}

      {isMobile && <ChevronRight className="w-4 h-4" />}
    </motion.a>
  );
};

export default NavbarWithTokenSale;