"use client";
import React, { useEffect, useRef, useState } from 'react';
import { AnimatePresence, motion, Variants } from 'framer-motion';
import { ArrowUpRight, ChevronDown, Shield, Timer, BarChart3, Users } from 'lucide-react';
import { ConnectWallet, useAddress } from '@thirdweb-dev/react';
import Countdown from 'react-countdown';
import { cn } from '../lib/utils';
import { scorp } from '../images';

// Types
interface CountdownRendererProps {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
  completed: boolean;
}

interface HyperTextProps {
  text: string;
  duration?: number;
  framerProps?: Variants;
  className?: string;
  animateOnLoad?: boolean;
}

// HyperText Component
const alphabets = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");
const getRandomInt = (max: number) => Math.floor(Math.random() * max);

function HyperText({
  text,
  duration = 800,
  framerProps = {
    initial: { opacity: 0, y: -10 },
    animate: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: 3 },
  },
  className,
  animateOnLoad = true,
}: HyperTextProps) {
  const [displayText, setDisplayText] = useState(text.split(""));
  const [trigger, setTrigger] = useState(false);
  const interations = useRef(0);
  const isFirstRender = useRef(true);

  const triggerAnimation = () => {
    interations.current = 0;
    setTrigger(true);
  };

  useEffect(() => {
    const interval = setInterval(
      () => {
        if (!animateOnLoad && isFirstRender.current) {
          clearInterval(interval);
          isFirstRender.current = false;
          return;
        }
        if (interations.current < text.length) {
          setDisplayText((t) =>
            t.map((l, i) =>
              l === " "
                ? l
                : i <= interations.current
                  ? text[i]
                  : alphabets[getRandomInt(26)],
            ),
          );
          interations.current = interations.current + 0.1;
        } else {
          setTrigger(false);
          clearInterval(interval);
        }
      },
      duration / (text.length * 10),
    );
    return () => clearInterval(interval);
  }, [text, duration, trigger, animateOnLoad]);

  return (
    <div
      className="overflow-hidden py-2 flex cursor-default scale-100"
      onMouseEnter={triggerAnimation}
    >
      <AnimatePresence mode="wait">
        {displayText.map((letter, i) => (
          <motion.h1
            key={i}
            className={cn("font-mono", letter === " " ? "w-3" : "", className)}
            {...framerProps}
          >
            {letter.toUpperCase()}
          </motion.h1>
        ))}
      </AnimatePresence>
    </div>
  );
}

// Hero Section Component
const HeroSection: React.FC = () => {
  const address = useAddress();
  const IDO_END_DATE = new Date('2024-11-10T12:00:00Z').getTime();

  // Stats data
  const stats = [
    { label: "Token Price", value: "$0.002", icon: <BarChart3 className="w-5 h-5" /> },
    { label: "Total Supply", value: "150M", icon: <Shield className="w-5 h-5" /> },
    { label: "Initial MCap", value: "$30k", icon: <BarChart3 className="w-5 h-5" /> },
    { label: "Scorpion Miners", value: "1,234+", icon: <Users className="w-5 h-5" /> },
  ];

  // Countdown renderer
  const countdownRenderer = ({ days, hours, minutes, seconds, completed }: CountdownRendererProps) => {
    if (completed) return <span>Sale Ended</span>;

    const timeUnits = [
      { value: days, label: "Days" },
      { value: hours, label: "Hours" },
      { value: minutes, label: "Minutes" },
      { value: seconds, label: "Seconds" }
    ];

    return (
      <div className="flex gap-4">
        {timeUnits.map(({ value, label }) => (
          <div 
            key={label} 
            className="flex flex-col items-center bg-blue-900/20 px-4 py-3 rounded-xl border border-blue-800/30"
          >
            <span className="text-2xl md:text-3xl font-bold text-orange-400">
              {value.toString().padStart(2, '0')}
            </span>
            <span className="text-xs text-blue-100/60 mt-1">{label}</span>
          </div>
        ))}
      </div>
    );
  };

  return (
    <section className="relative min-h-screen pt-32 pb-20 px-4 overflow-hidden bg-slate-950" id="home">
      {/* Background Effects */}
      <div className="absolute inset-0 overflow-hidden">
        <div className="absolute w-[500px] h-[500px] bg-orange-500/10 rounded-full blur-3xl -top-48 -left-48 animate-pulse" />
        <div className="absolute w-[500px] h-[500px] bg-blue-500/10 rounded-full blur-3xl -bottom-48 -right-48 animate-pulse" />
      </div>

      {/* Content Container */}
      <div className="relative max-w-7xl mx-auto">
        {/* Main Content */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 lg:gap-20 items-center">
          {/* Left Column - Text Content */}
          <div className="space-y-8">
            {/* Sale Status Badge */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              <div className="inline-flex items-center gap-2 px-4 py-2 rounded-full bg-blue-500/10 border border-orange-500/20">
                <span className="relative flex h-3 w-3">
                  <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-orange-400 opacity-75"></span>
                  <span className="relative inline-flex rounded-full h-3 w-3 bg-blue-500"></span>
                </span>
                <span className="text-blue-400 font-medium">BASE Testnet is Live</span>
              </div>
            </motion.div>

            <motion.div
  initial={{ opacity: 0, y: 20 }}
  animate={{ opacity: 1, y: 0 }}
  transition={{ duration: 0.5, delay: 0.1 }}
  className="space-y-4"
>
  <h1 className="text-4xl md:text-6xl font-bold">
    <div className="inline-flex items-baseline">
      <span className="bg-gradient-to-r from-orange-400 via-orange-300 to-yellow-400 text-transparent bg-clip-text mr-3">
        Harness the 
      </span>
    </div>
    <div className="inline-flex items-baseline">
      <span className="bg-gradient-to-r from-orange-400 via-orange-300 to-yellow-400 text-transparent bg-clip-text mr-3">
        potential of
      </span>
      <HyperText 
        text="$SCORP"
        className="text-blue-100"
        duration={800}
        framerProps={{
          initial: { opacity: 0, scale: 0.9 },
          animate: { opacity: 1, scale: 1 },
          exit: { opacity: 0, scale: 1.1 },
        }}
      />
    </div>
  </h1>
  <p className="text-lg text-blue-100 max-w-xl">
    Earn, trade, and unlock limitless possibilities in the Web3 and Telegram ecosystem.
  </p>
</motion.div>

            {/* Call to Action */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
              className="flex flex-col sm:flex-row gap-4"
            >
              {!address ? (
                <ConnectWallet
                  theme="dark"
                  btnTitle="Connect Wallet"
                  className="!bg-gradient-to-r from-orange-500 to-orange-600 !text-white 
                    font-bold rounded-xl transition-all hover:opacity-90 sm:w-auto"
                />
              ) : (
                <motion.a
                  href="/whitelist"
                  className="inline-flex items-center justify-center px-8 py-4 bg-gradient-to-r 
                    from-orange-500 to-orange-600 text-white font-bold rounded-xl 
                    shadow-lg hover:opacity-90 transition-all group"
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                >
                  <span className="flex items-center gap-2">
                    Get Whitelisted
                    <Shield className="w-5 h-5 group-hover:translate-x-0.5 
                      group-hover:-translate-y-0.5 transition-transform" />
                  </span>
                </motion.a>
              )}
              <motion.a
                href="https://t.me/scorpion_world_bot"
                className="inline-flex items-center justify-center px-8 py-4 bg-blue-900/20 
                  text-blue-100 font-bold rounded-xl hover:bg-blue-900/30 
                  transition-all group border border-blue-800/30"
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                target='_blank'
              >
                Catch Scorpion
                <ChevronDown className="w-5 h-5 ml-2 group-hover:translate-y-0.5 transition-transform" />
              </motion.a>
            </motion.div>

            
            {/* <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.3 }}
              className="space-y-4"
            >
              <div className="flex items-center gap-2 text-blue-100">
                <Timer className="w-5 h-5 text-orange-400" />
                <span>Whitelist Entry Will Starts In:</span>
              </div>
              <Countdown 
                date={IDO_END_DATE}
                renderer={countdownRenderer}
              />
            </motion.div> */}
          </div>

          {/* Right Column - Token Visual */}
          <motion.div
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.8 }}
            className="relative"
          >
            <div className="relative aspect-square max-w-md mx-auto">
              {/* Token Image with Glow Effect */}
              <div className="absolute inset-0 flex items-center justify-center">
                <motion.div
                  className="absolute w-full h-full bg-orange-400/20 rounded-full blur-3xl"
                  animate={{
                    scale: [1, 1.2, 1],
                    opacity: [0.5, 0.8, 0.5],
                  }}
                  transition={{
                    duration: 4,
                    repeat: Infinity,
                    ease: "easeInOut",
                  }}
                />
                <img
                  src={scorp}
                  alt="SCORP Token"
                  className="relative w-64 h-64 rounded-full z-10"
                />
              </div>

              {/* Floating Elements */}
              <div className="absolute inset-0">
                {[...Array(5)].map((_, i) => (
                  <motion.div
                    key={i}
                    className="absolute"
                    style={{
                      top: `${Math.random() * 100}%`,
                      left: `${Math.random() * 100}%`,
                    }}
                    animate={{
                      y: [0, -20, 0],
                      scale: [1, 1.1, 1],
                      rotate: [0, 360],
                    }}
                    transition={{
                      duration: 3 + Math.random() * 2,
                      repeat: Infinity,
                      delay: i * 0.2,
                    }}
                  >
                    <div className="w-8 h-8 bg-orange-400/20 rounded-full backdrop-blur-sm" />
                  </motion.div>
                ))}
              </div>
            </div>
          </motion.div>
        </div>

        {/* Stats Section */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.4 }}
          className="mt-20"
        >
          <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
            {stats.map((stat, index) => (
              <motion.div
                key={stat.label}
                className="bg-blue-900/20 backdrop-blur-sm p-4 rounded-xl border border-blue-800/30"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.1 * index }}
              >
                <div className="flex items-center gap-2 text-orange-400 mb-2">
                  {stat.icon}
                  <span className="text-sm font-medium">{stat.label}</span>
                </div>
                <span className="text-2xl font-bold text-blue-100">{stat.value}</span>
              </motion.div>
            ))}
          </div>
        </motion.div>

        {/* Scroll Indicator */}
        <motion.div
          className="absolute bottom-8 left-1/2 transform -translate-x-1/2"
          animate={{ y: [0, 10, 0] }}
          transition={{ duration: 1.5, repeat: Infinity }}
        >
          <ChevronDown className="w-6 h-6 text-blue-100/60" />
        </motion.div>
      </div>
    </section>
  );
};

export default HeroSection;